import '@fortawesome/fontawesome-free/css/all.css'
import React, { useState } from "react";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

function FrontDesk() {
  const agentDisplayName = {
    "PlannerAgent": "Sarah (Planner Agent)",
    "ExecutorAgent": "Wendy (Executor Agent)",
    "JobBrokerAgent": "Simon (Job Broker Agent)",
    "CalculatorAgent": "Vlad (Calculator Agent)",
    "BrokerAgent": "Leroy (Broker Agent)"
  };
  
  const agentAvatar = {
    "PlannerAgent": "plannerAgent.jpeg",
    "ExecutorAgent": "executorAgent.jpeg",
    "JobBrokerAgent": "jobBrokerAgent.jpeg",
    "CalculatorAgent": "calculatorAgent.jpeg",
    "BrokerAgent": "brokerAgent.jpeg"
  };
  
  const emptyLog = {
    "messages": [],
    "result": null
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [chatLog, setChatLog] = useState(emptyLog);

  return (
    <div className="container mx-auto bg-gray-200 rounded-xl shadow border p-8 pb-4 m-10 w-3/4">         
        <div className="text-gray-700 text-lg font-bold mr-0 mb-1">
            What do you want me to do?
        </div>
        <form className="flex items-center mb-2" onSubmit={(e) => handleSubmit(e, setChatLog, setIsLoading, setIsExpanded, emptyLog)}>
          <input
            className="border rounded-lg p-2 mr-2 flex-auto"
            name="prompt"
            id="prompt"
          />
          <button
            className={`bg-blue-500 hover:bg-blue-700 text-white font-bold mr-2 py-2 px-4 rounded-lg`}
            type="submit"
          >
            <i className={`w-4 ${isLoading ? `fa-solid fa-spinner animate-spin` : `fas fa-wand-magic-sparkles`}`}></i>
          </button>  
          <button
            className={`${chatLog.result == null ? `bg-gray-300` : `bg-blue-500 hover:bg-blue-700 text-white`} font-bold py-2 px-4 rounded-lg`}
            disabled={chatLog.result == null}
            type="button"
            onClick={() => setIsExpanded(!isExpanded)}            
          >
            <i className={`fa-solid ${isExpanded ? `fa-down-left-and-up-right-to-center` : `fa-up-right-and-down-left-from-center`}`}></i>
          </button>                            
        </form>
        <div className={`text-gray-700 text-lg mr-0 ${isExpanded ? `visible h-auto` : `invisible h-0`}`}>
          <ul>
            {
              chatLog.messages.map(message => (
                <AgentCell message={message} key={message.id} agentDisplayName={agentDisplayName[message.agentName]} avatar={agentAvatar[message.agentName]} />
              ))
            }
          </ul>
        </div>        
        <div className={`${ chatLog.result != null ? `visible h-auto` : `invisible h-0` }`}>
          <p className="text-gray-700 text-lg font-bold mr-0 mb-1">
            Here you are:
          </p>
          <p className="text-gray-700 text-lg mr-0 mb-1">
            {chatLog.result}
          </p>
        </div>
    </div>
  );
}

function handleSubmit(event, setChatLog, setIsLoading, setIsExpanded, emptyLog) {
  event.preventDefault();
  
  setIsLoading(true);
  setChatLog(emptyLog);
  setIsExpanded(false);
  
  const form = event.target;
  const input = form.prompt;
  const value = input.value;
  console.log(value);
  // Make the API call
  axios.get(`${API_URL}/ask`, {
      params: {
        prompt: value
      }
  })
  .then(response => {
    setIsLoading(false);
    setChatLog(response.data);
  })
  .catch(error => {
    setIsLoading(false);
    console.error(error);
  });
}

const AgentCell = (props) => {
  const { id, agentName, prompt, completion } = props.message;
  
  return (
    <li className='bg-blue-50 rounded-lg mb-2 p-3 flex items-top justify-content-between'>
      <img className='w-10 h-10 rounded-full' src={props.avatar} alt='avatar' />
      <div className='ml-2 w-full mr-2'>
        <h2 className="font-bold">{props.agentDisplayName}</h2>
        <div className="relative group">
          <p className="bg-purple-100 rounded-tl-sm rounded-tr-sm pl-2 pr-2 w-full" style={{whiteSpace: 'pre-line'}}>{prompt}</p>
          <CopyPasteHover/>
        </div>
        <div className="relative group">
          <p className="bg-green-100 rounded-bl-sm rounded-br-sm pl-2 pr-2 pb-1 w-full" style={{whiteSpace: 'pre-line'}}>{completion}</p>
          <CopyPasteHover/>
        </div>
      </div>
    </li> 
  )
}

const CopyPasteHover = (props) => {
  return (
    <div className="absolute top-0 right-0 hidden group-hover:block">
      <button className="bg-gray-300 rounded-lg py-1 px-2 p-2 mr-2 mt-3" disabled="true">
        <i className="fa-regular fa-pencil-alt"></i>
      </button>
      <button className="bg-blue-500 hover:bg-blue-700 text-white rounded-lg py-1 px-3 p-2 mr-3 mt-3">
        <i className="fa-regular fa-clipboard"></i>
      </button>
    </div>
  )
}

export default FrontDesk;